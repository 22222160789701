import {
	AvailableSectionsIdsSnakeCase,
	NavigationEventSource,
} from 'Components/pages/VehicleDetailsRedesign/VehicleDetailsRedesign.types';
import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerSectionLinksClickedEvent = (
	section: AvailableSectionsIdsSnakeCase | string,
	source: NavigationEventSource,
): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: VEHICLE_DETAILS,
				customData: { label: section },
				name: `dlr_${source}_link_clicked`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
	]);
};
