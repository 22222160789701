/* eslint-disable max-classes-per-file */

export class DealerError extends Error {
	constructor(functionName: string) {
		super();
		// Ensure the name of this error is the same as the class name
		this.name = this.constructor.name;
		this.message = `There was an error with ${functionName}`;
		// This clips the constructor invocation from the stack trace.
		// It's not absolutely essential, but it does make the stack trace a little nicer.
		Error?.captureStackTrace?.(this, this.constructor);
	}
}
/* DATA PROVIDER */
export class ComposerClientError extends DealerError {}
export class DealerCoreClientError extends DealerError {}
export class BiddingClientError extends DealerError {}
export class TransactionCoreClientError extends DealerError {}
export class VehicleCoreClientError extends DealerError {}
export class PaymentsCoreClientError extends DealerError {}
export class PDFClientError extends DealerError {}
export class ReportingInsightsClientError extends DealerError {}
export class DealershipPlatformError extends DealerError {}
export class OtpGeneratorError extends DealerError {}
export class FetchVehicleListError extends DealerError {}
export class MapFilesError extends DealerError {}
export class ServiceHistoryError extends DealerError {}

/* MOTORWAY SERVICE */
export class WithdrawalError extends DealerError {}
export class KycError extends DealerError {}

/* THIRD PARTY SERVICE */
export class OptimizelyError extends DealerError {}
