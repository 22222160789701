import { GA_VEHICLE_DETAILS_CATEGORIES } from 'Components/pages/vehicleDetails/VehicleDetails.helpers';
import type { GalleryImage } from 'Pages/vehicles/helpers/vehicle.schema';

import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;
const { GALLERY } = ANALYTICS_CATEGORIES;

export const triggerHeroGalleryClosed = (image?: Pick<GalleryImage, 'kind'>): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		name: 'dlr_hero_gallery_closed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
		...(image && { customData: { label: image.kind } }),
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery closed',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryNextClicked = (image?: Pick<GalleryImage, 'kind'>): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		name: 'dlr_hero_gallery_next_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
		...(image && { customData: { label: image.kind } }),
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery next button clicked',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryOpen = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: imageKind },
		name: 'dlr_hero_gallery_open',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Open',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: imageKind,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryPrevClicked = (image?: Pick<GalleryImage, 'kind'>): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		name: 'dlr_hero_gallery_prev_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
		...(image && { customData: { label: image.kind } }),
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery prev button clicked',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryImageViewed = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: imageKind },
		name: 'dlr_hero_gallery_image_viewed',
		schemaName: SNOWPLOW_EVENTS.LOAD.SCHEMA,
		version: SNOWPLOW_EVENTS.LOAD.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery image viewed',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: imageKind,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerSectionImageClicked = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: imageKind },
		name: 'dlr_section_image_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerHeroGalleryTabClicked = (section: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: section },
		name: 'dlr_hero_gallery_tab_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerHeroGalleryZoomInClicked = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: imageKind },
		name: 'dlr_hero_gallery_zoom_in_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerHeroGalleryZoomOutClicked = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: imageKind },
		name: 'dlr_hero_gallery_zoom_out_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerHeroGalleryZoomOriginalClicked = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: imageKind },
		name: 'dlr_hero_gallery_zoom_original_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
